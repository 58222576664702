export const reContactConstants = {
    
  GetContact: {
    REQUEST: "GET_CONTACT_REQUEST",
    SUCCESS: "GET_CONTACT_SUCCESS",
    FAILURE: "GET_CONTACT_FAILURE",
  },
  SendContact: {
      REQUEST: 'SEND_CONTACT_REQUEST',
      SUCCESS: 'SEND_CONTACT_SUCCESS',
      FAILURE: 'SEND_CONTACT_FAILURE',
  }
};