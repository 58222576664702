export const helpsConstants = {
	GetHelpById: {
		REQUEST : "GET_HELP_BY_ID_REQUEST",
		SUCCESS : "GET_HELP_BY_ID_SUCCESS",
		FAILURE : "GET_HELP_BY_ID_FAILURE",
		CLEAR   : "GET_HELP_BY_ID_CLEAR"
	},
	GetHelpByTagId: {
		REQUEST : "GET_HELP_BY_TAG_ID_REQUEST",
		SUCCESS : "GET_HELP_BY_TAG_ID_SUCCESS",
		FAILURE : "GET_HELP_BY_TAG_ID_FAILURE"
	},
	GetAllHelpTags: {
		REQUEST : "GET_All_HELP_TAGS_REQUEST",
		SUCCESS : "GET_All_HELP_TAGS_SUCCESS",
		FAILURE : "GET_All_HELP_TAGS_FAILURE"
	},
	GetAllHelps: {
		REQUEST : "GET_All_HELPS_REQUEST",
		SUCCESS : "GET_All_HELPS_SUCCESS",
		FAILURE : "GET_All_HELPS_FAILURE"
	},
	CreateHelp: {
		REQUEST : "POST_NEW_HELP_REQUEST",
		SUCCESS : "POST_NEW_HELP_SUCCESS",
		FAILURE : "POST_NEW_HELP_FAILURE"
	},
	ChangeHelp: {
		REQUEST : "POST_UPDATED_HELP_REQUEST",
		SUCCESS : "POST_UPDATED_HELP_SUCCESS",
		FAILURE : "POST_UPDATED_HELP_FAILURE"
	},
	DeleteHelp: {
		REQUEST : "DELETE_HELP_REQUEST",
		SUCCESS : "DELETE_HELP_SUCCESS",
		FAILURE : "DELETE_HELP_FAILURE"
	},
	CreateHelpTag: {
		REQUEST : "POST_NEW_HELP_TAG_REQUEST",
		SUCCESS : "POST_NEW_HELP_TAG_SUCCESS",
		FAILURE : "POST_NEW_HELP_TAG_FAILURE"
	},
	ChangeHelpTag: {
		REQUEST : "POST_UPDATED_HELP_TAG_REQUEST",
		SUCCESS : "POST_UPDATED_HELP_TAG_SUCCESS",
		FAILURE : "POST_UPDATED_HELP_TAG_FAILURE"
	},
	DeleteHelpTag: {
		REQUEST : "DELETE_HELP_TAG_REQUEST",
		SUCCESS : "DELETE_HELP_TAG_SUCCESS",
		FAILURE : "DELETE_HELP_TAG_FAILURE"
	},
	SetUserFeedback: {
		REQUEST : "SET_USER_HELP_FEEDBACK_REQUEST",
		SUCCESS : "SET_USER_HELP_FEEDBACK_SUCCESS",
		FAILURE : "SET_USER_HELP_FEEDBACK_FAILURE"
	},
	GetAllRoles: {
		REQUEST : "GET_ALL_ROLES_REQUEST",
		SUCCESS : "GET_ALL_ROLES_SUCCESS",
		FAILURE : "GET_ALL_ROLES_FAILURE",
		CLEAR 	: "ALL_ROLES_CLEAR"
	}
}
