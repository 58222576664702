import {
	controlSubsystemConstants
}from "./constants"

const initialState = {
	appealsPaged    : null,
	changed         : null,
	downloadFile    : null,
	error           : null,
	info            : null,
	isLoading      	: false,
	isSending       : false,
	list            : null,
	orgName         : null,
	paged           : null,
	receivedRequest : null,
	response        : null,
	sentRequests    : null,
	subsystemOrgs   : null
}

export default (state = initialState, action) => {
	switch (action.type){
		case controlSubsystemConstants.DownloadSubsystemFile.REQUEST:
			return {
				...state,
				downloadFile : null,
				isLoading    : true
			}
		case controlSubsystemConstants.DownloadSubsystemFile.FAILURE:
			return {
				...state,
				downloadFile : null,
				error        : action.payload.result,
				isLoading    : false
			}
		case controlSubsystemConstants.DownloadSubsystemFile.SUCCESS:
			return {
				...state,
				downloadFile : action.payload.result,
				isLoading    : false
			}
		case controlSubsystemConstants.getOrgsByNameOrUnpPaged.REQUEST:
		case controlSubsystemConstants.getReceivedRequestsPaged.REQUEST:
		case controlSubsystemConstants.getSentRequestsPaged.REQUEST:
		case controlSubsystemConstants.createRequest.REQUEST:
		case controlSubsystemConstants.createResponse.REQUEST:
		case controlSubsystemConstants.getAppealList.REQUEST:
		case controlSubsystemConstants.getReOrgAppealInfo.REQUEST:
		case controlSubsystemConstants.getReOrgAppealResolutionsPaged.REQUEST:
		case controlSubsystemConstants.getAppealInfoByOrgPaged.REQUEST:
		case controlSubsystemConstants.getAccessRequestById.REQUEST:
		case controlSubsystemConstants.getReceivedRequest.REQUEST:
		case controlSubsystemConstants.getSentRequest.REQUEST:
		case controlSubsystemConstants.getResponseAccess.REQUEST:
		case controlSubsystemConstants.getOrgName.REQUEST:
			return {
				...state,
				changed   : null,
				isSending : true
			}
		case controlSubsystemConstants.getOrgsByNameOrUnpPaged.FAILURE:
		case controlSubsystemConstants.getReceivedRequestsPaged.FAILURE:
		case controlSubsystemConstants.getSentRequestsPaged.FAILURE:
		case controlSubsystemConstants.createRequest.FAILURE:
		case controlSubsystemConstants.createResponse.FAILURE:
		case controlSubsystemConstants.getAppealList.FAILURE:
		case controlSubsystemConstants.getReOrgAppealInfo.FAILURE:
		case controlSubsystemConstants.getReOrgAppealResolutionsPaged.FAILURE:
		case controlSubsystemConstants.getAppealInfoByOrgPaged.FAILURE:
		case controlSubsystemConstants.getAccessRequestById.FAILURE:
		case controlSubsystemConstants.getReceivedRequest.FAILURE:
		case controlSubsystemConstants.getSentRequest.FAILURE:
		case controlSubsystemConstants.getResponseAccess.FAILURE:
		case controlSubsystemConstants.getOrgName.FAILURE:
			return {
				...state,
				error     : action.payload.result,
				isSending : false

			}
		case controlSubsystemConstants.getAppealList.SUCCESS:
			return {
				...state,
				list      : action.payload.result,
				isSending : false
			}
		case controlSubsystemConstants.getReOrgAppealInfo.SUCCESS:
		case controlSubsystemConstants.getAccessRequestById.SUCCESS:
		case controlSubsystemConstants.getReceivedRequest.SUCCESS:
		case controlSubsystemConstants.getSentRequest.SUCCESS:
			return {
				...state,
				info      : action.payload.result,
				isSending : false
			}
		case controlSubsystemConstants.getReOrgAppealResolutionsPaged.SUCCESS:
			return {
				...state,
				paged     : action.payload.result,
				isSending : false
			}
		case controlSubsystemConstants.getAppealInfoByOrgPaged.SUCCESS:
			return {
				...state,
				appealsPaged : action.payload.result,
				isSending    : false
			}
		case controlSubsystemConstants.getResponseAccess.SUCCESS:
			return {
				...state,
				response  : action.payload.result,
				isSending : false
			}
		case controlSubsystemConstants.createRequest.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Запрос успешно создан!'
			}
		case controlSubsystemConstants.createResponse.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Ответ успешно создан!'
			}
		case controlSubsystemConstants.getOrgsByNameOrUnpPaged.SUCCESS:
			return {
				...state,
				isSending     : false,
				subsystemOrgs : action.payload.result
			}
		case controlSubsystemConstants.getReceivedRequestsPaged.SUCCESS:
			return {
				...state,
				isSending       : false,
				receivedRequest : action.payload.result
			}
		case controlSubsystemConstants.getSentRequestsPaged.SUCCESS:
			return {
				...state,
				isSending    : false,
				sentRequests : action.payload.result
			}
		case controlSubsystemConstants.setOrgName:
			return {
				...state,
				orgName: action.payload
			}
		case controlSubsystemConstants.getOrgName.SUCCESS:
			return {
				...state,
				isSending : false,
				orgName   : action.payload.result
			}
		case controlSubsystemConstants.clearSubsystem:
			return {
				...state,
				appealsPaged    : null,
				changed         : null,
				error           : null,
				info            : null,
				isSending       : false,
				list            : null,
				paged           : null,
				receivedRequest : null,
				response        : null,
				sentRequests    : null,
				subsystemOrgs   : null
			}
		default:
			return state
	}
}