import {reContactConstants} from "./constans";
  
  const initialState =
  {
    isContactSend: false, 
    isSending: false,
    contact: {}
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case   reContactConstants.GetContact.REQUEST:
        return {
          ...state,
          isSending: true
        }
      case   reContactConstants.GetContact.SUCCESS:
        return {
          ...state,
          isSending: false,
          contact: action.payload.result
        }
      case   reContactConstants.GetContact.FAILURE:
        return {
          ...state,
          isSending: false,
          error: action.error
        }
        case reContactConstants.SendContact.REQUEST:
          return {
            ...state,
            isContactSend: null
          };
        case reContactConstants.SendContact.SUCCESS:
          return {
            ...state,
            isContactSend: true
          };
        case reContactConstants.SendContact.FAILURE:
          return {
            ...state,
            isContactSend: false,
            error: action.err,
          };
      default:
        return state
    }
  }