import {
	exportToExcelConstants
}from "./constans"

const initialState =
  {
  	exportExcelFile    : null,
  	isExcelFileLoading : false
  }

export default function (state = initialState, action){
	switch (action.type){
		case exportToExcelConstants.ExportListToExcel.REQUEST :
			return {
				...state,
				exportExcelFile    : null,
				isExcelFileLoading : true
			}
		case exportToExcelConstants.ExportListToExcel.SUCCESS :
			return {
				...state,
				exportExcelFile    : action.payload.result,
				isExcelFileLoading : false
			}
		case exportToExcelConstants.ExportListToExcel.FAILURE :
			return {
				...state,
				exportExcelFile    : null,
				isExcelFileLoading : false
			}
		case exportToExcelConstants.ExportListToExcel.СLEAR :
			return {
				...state,
				exportExcelFile    : null,
				isExcelFileLoading : false
			}
		default :
			return state
	}
}