import {
	fastReportConstants
}from './constants'

const initialState = {
	changedItem               : null,
	constructureSettings      : [],
	currentItem               : null,
	filters                   : null,
	isChanged                 : false,
	isCurrent                 : true,
	isDeleted                 : false,
	isRegionsDistrictsLoading : false,
	isReviewSettingsLoading   : false,
	isSending                 : false,
	list                      : null,
	listChildReOrgs           : [],
	listOrgs                  : null,
	regionsDistricts          : [],
	reviewSettings            : {}
}

export default function (state = initialState, action){
	switch (action.type){
		case fastReportConstants.CLEAR_RESULT :
			return {
				...state,
				changedItem : null,
				isChanged   : false,
				isCurrent   : true,
				isDeleted   : false
			}
		case fastReportConstants.DeleteFR.REQUEST :
			return {
				...state,
				isCurrent : Boolean(action.payload.isCurrent),
				isDeleted : false,
				isSending : true
			}
		case fastReportConstants.DeleteFR.SUCCESS :
			return {
				...state,
				currentItem : (action.payload.result && state.isCurrent) ? null : state.currentItem,
				isDeleted   : action.payload.result,
				isSending   : false
			}
		case fastReportConstants.DeleteFR.FAILURE :
			return {
				...state,
				error     : action.payload,
				isDeleted : false,
				isSending : false
			}
		case fastReportConstants.SettingFR.REQUEST :
			return {
				...state,
				isChanged : false,
				isSending : true
			}
		case fastReportConstants.SettingFR.SUCCESS :
			return {
				...state,
				isChanged : action.payload.result,
				isSending : false
			}
		case fastReportConstants.SettingFR.FAILURE :
			return {
				...state,
				error     : action.payload,
				isChanged : false,
				isSending : false
			}
		case fastReportConstants.GetAllConstructureSettings.REQUEST :
			return {
				...state,
				constructureSettings : [],
				isSending            : true
			}
		case fastReportConstants.GetAllConstructureSettings.SUCCESS :
			return {
				...state,
				constructureSettings : action.payload.result,
				isSending            : false
			}
		case fastReportConstants.GetAllConstructureSettings.FAILURE :
			return {
				...state,
				error     : action.payload,
				isSending : false
			}
		case fastReportConstants.GetAllRegionsDistricts.REQUEST :
			return {
				...state,
				isRegionsDistrictsLoading : true,
				isSending                 : true
			}
		case fastReportConstants.GetAllRegionsDistricts.SUCCESS :
			return {
				...state,
				isRegionsDistrictsLoading : false,
				isSending                 : false,
				regionsDistricts          : action.payload.result
			}
		case fastReportConstants.GetAllRegionsDistricts.FAILURE :
			return {
				...state,
				error                     : action.payload,
				isRegionsDistrictsLoading : false,
				isSending                 : false
			}
		case fastReportConstants.GetReviewSettings.REQUEST :
			return {
				...state,
				isReviewSettingsLoading : true,
				isSending               : true
			}
		case fastReportConstants.GetReviewSettings.SUCCESS :
			return {
				...state,
				isReviewSettingsLoading : false,
				isSending               : false,
				reviewSettings          : action.payload.result
			}
		case fastReportConstants.GetReviewSettings.FAILURE :
			return {
				...state,
				error                   : action.payload,
				isReviewSettingsLoading : false,
				isSending               : false
			}
		case fastReportConstants.GetResolutionAuthors.REQUEST :
			return {
				...state,
				isReviewSettingsLoading : true,
				isSending               : true
			}
		case fastReportConstants.GetResolutionAuthors.SUCCESS :
			return {
				...state,
				isReviewSettingsLoading : false,
				isSending               : false,
				reviewSettings          : { ...state.reviewSettings,
					resolutionAuthors: action.payload.result }
			}
		case fastReportConstants.GetResolutionAuthors.FAILURE :
			return {
				...state,
				error                   : action.payload,
				isReviewSettingsLoading : false,
				isSending               : false
			}
		case fastReportConstants.GetList.REQUEST :
			return {
				...state,
				filters   : action.payload,
				isSending : true
			}
		case fastReportConstants.GetList.SUCCESS :
		{
			return {
				...state,
				isSending : false,
				list      : action.payload.result.userFastReportShortSettings.items.reduce((accum, next) => {
					return [
						...accum,
						{
							...next,
							key: next.id
						}
					]
				}, [])
			}
		}
		case fastReportConstants.GetList.FAILURE :
			return {
				...state,
				error     : action.payload,
				isSending : false
			}
		case fastReportConstants.GetById.REQUEST :
			return {
				...state,
				isCurrent : Boolean(action.payload.isCurrent),
				isSending : true
			}
		case fastReportConstants.GetById.SUCCESS :
		{
			const obj = {}
			if (state.isCurrent)
				obj.currentItem = action.payload.result
			else
				obj.changedItem = action.payload.result
			return {
				...state,
				...obj,
				isSending: false
			}
		}
		case fastReportConstants.GetById.FAILURE :
			return {
				...state,
				error     : action.payload,
				isCurrent : true,
				isSending : false
			}
		case fastReportConstants.GetChildReOrgsForDisplay.REQUEST :
			return {
				...state,
				isSending       : true,
				listChildReOrgs : []
			}
		case fastReportConstants.GetChildReOrgsForDisplay.SUCCESS :
		{
			return {
				...state,
				isSending       : false,
				listChildReOrgs : action.payload.result
			}
		}
		case fastReportConstants.GetChildReOrgsForDisplay.FAILURE :
			return {
				...state,
				error     : action.payload,
				isSending : false
			}
		default :
			return state
	}
}
