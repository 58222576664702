export const resolutionsConstants = {
	CLEAR                  : 'CLEAR_RESOLUTION_RESULT',
	GetResolutionFilesById : {
		REQUEST : 'GET_RESOLUTION_FILES_BY_ID_REQUEST',
		SUCCESS : 'GET_RESOLUTION_FILES_BY_ID_SUCCESS',
		FAILURE : 'GET_RESOLUTION_FILES_BY_ID_FAILURE',
		CLEAR   : 'GET_RESOLUTION_FILES_BY_ID_CLEAR'
	},
	DownloadResolutionFile: {
		REQUEST : 'DOWNLOAD_RESOLUTION_FILE_BY_ID_REQUEST',
		SUCCESS : 'DOWNLOAD_RESOLUTION_FILE_BY_ID_SUCCESS',
		FAILURE : 'DOWNLOAD_RESOLUTION_FILE_BY_ID_FAILURE'
	},
	CreateResolution: {
		REQUEST : 'CREATE_RESOLUTION_REQUEST',
		SUCCESS : 'CREATE_RESOLUTION_SUCCESS',
		FAILURE : 'CREATE_RESOLUTION_FAILURE'
	},
	SignResolution: {
		REQUEST : 'SIGN_RESOLUTION_REQUEST',
		SUCCESS : 'SIGN_RESOLUTION_SUCCESS',
		FAILURE : 'SIGN_RESOLUTION_FAILURE'
	},
	GetResolutionFilesInfo: {
		REQUEST : "GET_RESOLUTION_FILE_INFO_REQUEST",
		SUCCESS : "GET_RESOLUTION_FILE_INFO_SUCCESS",
		FAILURE : "GET_RESOLUTION_FILE_INFO_FAILURE",
		CLEAR   : "GET_LIST_RA_CLEAR"
	},
	GetResolutionIntermediateFileInfo: {
		REQUEST : "GET_RESOLUTION_INTERMEDIATE_FILE_INFO_REQUEST",
		SUCCESS : "GET_RESOLUTION_INTERMEDIATE_FILE_INFO_SUCCESS",
		FAILURE : "GET_RESOLUTION_INTERMEDIATE_FILE_INFO_FAILURE",
		CLEAR   : "GET_LIST_RA_INTERMEDIATE_CLEAR"
	},
	SaveResolution: {
		REQUEST : 'SAVE_RESOLUTION_REQUEST',
		SUCCESS : 'SAVE_RESOLUTION_SUCCESS',
		FAILURE : 'SAVE_RESOLUTION_FAILURE'
	},
	ApproveResolution: {
		REQUEST : 'APPROVE_RESOLUTION_REQUEST',
		SUCCESS : 'APPROVE_RESOLUTION_SUCCESS',
		FAILURE : 'APPROVE_RESOLUTION_FAILURE'
	},
	GetExecutors: {
		REQUEST : 'GET_EXECUTORS_REQUEST',
		SUCCESS : 'GET_EXECUTORS_SUCCESS',
		FAILURE : 'GET_EXECUTORS_FAILURE'
	},
	GetResolutions: {
		REQUEST : 'GET_RESOLUTIONS_REQUEST',
		SUCCESS : 'GET_RESOLUTIONS_SUCCESS',
		FAILURE : 'GET_RESOLUTIONS_FAILURE'
	},
	ChangeExecutor: {
		REQUEST : 'CHANGE_EXECUTOR_REQUEST',
		SUCCESS : 'CHANGE_EXECUTOR_SUCCESS',
		FAILURE : 'CHANGE_EXECUTOR_FAILURE'
	},
	GetResolution: {
		REQUEST : 'GET_RESOLUTION_REQUEST',
		SUCCESS : 'GET_RESOLUTION_SUCCESS',
		FAILURE : 'GET_RESOLUTION_FAILURE'
	},
	UpdateResolution: {
		REQUEST : 'UPDATE_RESOLUTION_REQUEST',
		SUCCESS : 'UPDATE_RESOLUTION_SUCCESS',
		FAILURE : 'UPDATE_RESOLUTION_FAILURE'
	},
	UpdateApproval: {
		REQUEST : 'UPDATE_APPROVAL_REQUEST',
		SUCCESS : 'UPDATE_APPROVAL_SUCCESS',
		FAILURE : 'UPDATE_APPROVAL_FAILURE'
	},
	UpdateExecutionResult: {
		REQUEST : 'UPDATE_EXECUTION_RESULT_REQUEST',
		SUCCESS : 'UPDATE_EXECUTION_RESULT_SUCCESS',
		FAILURE : 'UPDATE_EXECUTION_RESULT_FAILURE'
	},
	DeleteResolution: {
		REQUEST : 'DELETE_RESOLUTION_REQUEST',
		SUCCESS : 'DELETE_RESOLUTION_SUCCESS',
		FAILURE : 'DELETE_RESOLUTION_FAILURE',
		CLEAR   : 'DELETE_RESOLUTION_CLEAR'
	},
	DeclineResolution: {
		REQUEST : 'DECLINE_RESOLUTION_REQUEST',
		SUCCESS : 'DECLINE_RESOLUTION_SUCCESS',
		FAILURE : 'DECLINE_RESOLUTION_FAILURE',
		CLEAR   : 'DECLINE_RESOLUTION_CLEAR'
	},
	ApproveFinalResult: {
		REQUEST : 'APPROVE_FINAL_RESULT_REQUEST',
		SUCCESS : 'APPROVE_FINAL_RESULT_SUCCESS',
		FAILURE : 'APPROVE_FINAL_RESULT_FAILURE'
	},
	FinaliseResolution: {
		REQUEST : 'FINALISE_RESOLUTION_REQUEST',
		SUCCESS : 'FINALISE_RESOLUTION_SUCCESS',
		FAILURE : 'FINALISE_RESOLUTION_FAILURE'
	},
	AssignFinalResultSigner: {
		REQUEST : 'ASSIGN_FINAL_RESULT_SIGNER_REQUEST',
		SUCCESS : 'ASSIGN_FINAL_RESULT_SIGNER_SUCCESS',
		FAILURE : 'ASSIGN_FINAL_RESULT_SIGNER_FAILURE'
	},
	GetCountResolutionChangeHistory: {
		REQUEST : 'GET_COUNT_RESOLUTION_HISTORY_CHANGE_REQUEST',
		SUCCESS : 'GET_COUNT_RESOLUTION_HISTORY_CHANGE_SUCCESS',
		FAILURE : 'GET_COUNT_RESOLUTION_HISTORY_CHANGE_FAILURE',
		CLEAR   : 'GET_COUNT_RESOLUTION_HISTORY_CHANGE_CLEAR'
	},
	GetResolutionChangeHistory: {
		REQUEST : 'GET_RESOLUTION_HISTORY_CHANGE_REQUEST',
		SUCCESS : 'GET_RESOLUTION_HISTORY_CHANGE_SUCCESS',
		FAILURE : 'GET_RESOLUTION_HISTORY_CHANGE_FAILURE',
		CLEAR   : 'GET_RESOLUTION_HISTORY_CHANGE_CLEAR'
	},
	ResetAllBools    : 'RESET_ALL_BOOLS',
	AddExecutorFiles : {
		REQUEST : 'ADD_EXECUTOR_FILES_REQUEST',
		SUCCESS : 'ADD_EXECUTOR_FILES_SUCCESS',
		FAILURE : 'ADD_EXECUTOR_FILES_FAILURE'
	},
	GetExecutorFiles: {
		REQUEST : 'GET_EXECUTOR_FILES_REQUEST',
		SUCCESS : 'GET_EXECUTOR_FILES_SUCCESS',
		FAILURE : 'GET_EXECUTOR_FILES_FAILURE'
	},
	AddResolutionFiles: {
		REQUEST : 'ADD_RESOLUTION_FILES_REQUEST',
		SUCCESS : 'ADD_RESOLUTION_FILES_SUCCESS',
		FAILURE : 'ADD_RESOLUTION_FILES_FAILURE'
	},

	AddIntermediateResult: {
		REQUEST : 'ADD_INTERMEDIATE_RESULT_REQUEST',
		SUCCESS : 'ADD_INTERMEDIATE_RESULT_SUCCESS',
		FAILURE : 'ADD_INTERMEDIATE_RESULT_FAILURE',
		CLEAR   : 'ADD_INTERMEDIATE_RESULT_CLEAR'
	},

	SignIntermediateResult: {
		REQUEST : 'SIGN_INTERMEDIATE_RESULT_REQUEST',
		SUCCESS : 'SIGN_INTERMEDIATE_RESULT_SUCCESS',
		FAILURE : 'SIGN_INTERMEDIATE_RESULT_FAILURE'
	},
	SendToRevision: {
		REQUEST : 'SEND_TO_REVISION_REQUEST',
		SUCCESS : 'SEND_TO_REVISION_SUCCESS',
		FAILURE : 'SEND_TO_REVISION_FAILURE',
		CLEAR  	: 'SEND_TO_REVISION_CLEAR'
	}
}
