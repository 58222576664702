import {
	helpsConstants
}from "./constants"

const initialState = {
	helps        : [],
	help         : {},
	tags         : [],
	onePost      : null,
	isSending    : false,
	isSended     : false,
	isChanged    : false,
	error        : null,
	isDeleted    : false,
	userFeedback : null,
	roles 		 : null
}

export default function (state = initialState, action){
	switch (action.type){
		case helpsConstants.GetAllRoles.REQUEST:
			return{
				...state,
				isSending : true,
				roles : null
			}

		case helpsConstants.GetAllRoles.FAILURE:
			return{
				...state,
				isSending : false,
				error : action.payload.err.data
			}

		case helpsConstants.GetAllRoles.SUCCESS:
			return{
				...state,
				isSending : false,
				roles : action.payload.result
			}
			
		case helpsConstants.GetAllRoles.CLEAR:
			return{
				...state,
				roles : null
			}

		case helpsConstants.GetHelpById.REQUEST:
			return {
				...state,
				isSending: true
			}
		case helpsConstants.GetHelpById.SUCCESS:
			return {
				...state,
				isSending : false,
				onePost   : action.payload.result
			}
		case helpsConstants.GetHelpById.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload.err.data
			}
		case helpsConstants.GetHelpById.CLEAR:
			return {
				...state,
				onePost: null
			}

		case helpsConstants.GetHelpByTagId.REQUEST:
			return {
				...state,
				isSending: true
			}
		case helpsConstants.GetHelpByTagId.SUCCESS:
			const helpState = { ...state.help }
			helpState[action.payload.result.helpTagId] = action.payload.result.helps

			return {
				...state,

				// help: action.payload.result.helps,
				help      : { ...helpState },
				isSending : false
			}

		case helpsConstants.GetHelpByTagId.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}

		case helpsConstants.GetAllHelpTags.REQUEST:
			return {
				...state,
				isSending: true
			}
		case helpsConstants.GetAllHelpTags.SUCCESS:
			return {
				...state,
				tags      : action.payload.result,
				isSending : false
			}
		case helpsConstants.GetAllHelpTags.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}

		case helpsConstants.GetAllHelps.REQUEST:
			return {
				...state,
				isSending: true
			}
		case helpsConstants.GetAllHelps.SUCCESS:
			return {
				...state,
				helps     : action.payload.result,
				isSending : false
			}
		case helpsConstants.GetAllHelps.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}

		case helpsConstants.CreateHelp.REQUEST:
			return {
				...state,
				isSended: null
			}
		case helpsConstants.CreateHelp.SUCCESS:
			return {
				...state,
				isSended: true
			}
		case helpsConstants.CreateHelp.FAILURE:
			return {
				...state,
				isSended : false,
				error    : action.payload.err.data
			}

		case helpsConstants.ChangeHelp.REQUEST:
			return {
				...state,
				isChanged: null
			}
		case helpsConstants.ChangeHelp.SUCCESS:
			return {
				...state,
				isChanged: true
			}
		case helpsConstants.ChangeHelp.FAILURE:
			return {
				...state,
				isChanged : false,
				error     : action.payload.err.data
			}

		case helpsConstants.DeleteHelp.REQUEST:
			return {
				...state,
				isDeleted: null
			}
		case helpsConstants.DeleteHelp.SUCCESS:
			return {
				...state,
				isDeleted: true
			}
		case helpsConstants.DeleteHelp.FAILURE:
			return {
				...state,
				isDeleted : false,
				error     : action.payload.err.data
			}

		case helpsConstants.CreateHelpTag.REQUEST:
			return {
				...state,
				isSended: null
			}
		case helpsConstants.CreateHelpTag.SUCCESS:
			return {
				...state,
				isSended: true
			}
		case helpsConstants.CreateHelpTag.FAILURE:
			return {
				...state,
				isSended : false,
				error    : action.payload.err.data
			}

		case helpsConstants.ChangeHelpTag.REQUEST:
			return {
				...state,
				isChanged: null
			}
		case helpsConstants.ChangeHelpTag.SUCCESS:
			return {
				...state,
				isChanged: true
			}
		case helpsConstants.ChangeHelpTag.FAILURE:
			return {
				...state,
				isChanged : false,
				error     : action.payload.err.data
			}

		case helpsConstants.DeleteHelpTag.REQUEST:
			return {
				...state,
				isDeleted: null
			}
		case helpsConstants.DeleteHelpTag.SUCCESS:
			return {
				...state,
				isDeleted: true
			}
		case helpsConstants.DeleteHelpTag.FAILURE:
			return {
				...state,
				isDeleted : false,
				error     : action.payload.err.data
			}

		case helpsConstants.SetUserFeedback.REQUEST:
			return {
				...state,
				userFeedback: null
			}
		case helpsConstants.SetUserFeedback.SUCCESS:
			return {
				...state,
				userFeedback: action.payload.result
			}
		case helpsConstants.SetUserFeedback.FAILURE:
			return {
				...state,
				userFeedback : null,
				error        : action.payload.err.data
			}

		default:
			return state
	}
}
