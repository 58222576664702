import { repetitionConstants } from "./constants";

const initialState = {
  //getPotentialRepeatedReOrgAppeals;
  getPotentialRepeatedReOrgAppealsLoading: false,
  potentialRepeatedReOrgAppeals: null,
  getPotentialRepeatedReOrgAppealsError: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    //get;
    case repetitionConstants.GetPotentialRepeatedReOrgAppeals.REQUEST:
      return {
        ...state,
        getPotentialRepeatedReOrgAppealsLoading: true,
        potentialRepeatedReOrgAppeals: null,
        getPotentialRepeatedReOrgAppealsError: null,
      };
    case repetitionConstants.GetPotentialRepeatedReOrgAppeals.SUCCESS:
      return {
        ...state,
        getPotentialRepeatedReOrgAppealsLoading: false,
        potentialRepeatedReOrgAppeals: action.payload.result,
      };
    case repetitionConstants.GetPotentialRepeatedReOrgAppeals.FAILURE:
      return {
        ...state,
        getPotentialRepeatedReOrgAppealsLoading: false,
        getPotentialRepeatedReOrgAppealsError: action.payload.err.data.errors,
      };
    default:
      return state;
  }
}
