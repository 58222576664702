export const homeConstants = {
  SEND_FEEDBACK_REQUEST: 'SEND_FEEDBACK_REQUEST',
  SEND_FEEDBACK_SUCCESS: 'SEND_FEEDBACK_SUCCESS',
  SEND_FEEDBACK_FAILURE: 'SEND_FEEDBACK_FAILURE',

  GetStatistics: {
    REQUEST: 'GET_STATISTICS_REQUEST',
    SUCCESS: 'GET_STATISTICS_SUCCESS',
    FAILURE: 'GET_STATISTICS_FAILURE'
  }
}