import { questionConstants } from "./constants";
import "antd/lib/notification/style/index.css";


const initialState = {
  isQuestionCreated: false,
  isQuestionEdited: false,
  isQuestionDeleted: false,
  isQuestionRestored: false,
  isSending: false,
  currentItem: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case questionConstants.GetAllQuestions.CLEAR:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: false,
        currentItem: {},
      };
    case questionConstants.GetAllQuestions.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.GetAllQuestions.SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload.result,
      };
    case questionConstants.GetAllQuestions.FAILURE:
      return {
        ...state,
        isSending: false,
      };



    case questionConstants.GetHelpsForMain.CLEAR:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: false,
        currentItem: {},
      };
    case questionConstants.GetHelpsForMain.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.GetHelpsForMain.SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload.result,
      };
    case questionConstants.GetHelpsForMain.FAILURE:
      return {
        ...state,
        isSending: false,
      };

    default:
      return state;
  }
}
