export const constrolConstants = {
  getMessagesWithResponses: {
    REQUEST: "GET_CALENDAR_DAYS_REQUEST",
    SUCCESS: "GET_CALENDAR_DAYS_SUCCESS",
    FAILURE: "GET_CALENDAR_DAYS_FAILURE",
  },
  getAllControlStatuses: {
    REQUEST: "GET_ALL_CONTROL_STATUSES_REQUEST",
    SUCCESS: "GET_ALL_CONTROL_STATUSES_SUCCESS",
    FAILURE: "GET_ALL_CONTROL_STATUSES_FAILURE",
  },
  createOrderMessageResponse: {
    REQUEST: "CREATE_ORDER_MESSAGE_RESPONSE_REQUEST",
    SUCCESS: "CREATE_ORDER_MESSAGE_RESPONSE_SUCCESS",
    FAILURE: "CREATE_ORDER_MESSAGE_RESPONSE_FAILURE",
  },
  getAdditionalOrderMessageFile: {
    SUCCESS: "GET_ORDER_MESSAGE_FILE_SUCCESS",
    FAILURE: "GET_ORDER_MESSAGE_FILE_FAILURE",
    REQUEST: "GET_ORDER_MESSAGE_FILE_REQUEST",
  },
  approveAdditionalOrderMessage: {
    SUCCESS: "APPROVE_SUCCESS",
    FAILURE: "APPROVE_FAILURE",
    REQUEST: "APPROVE_REQUEST",
  },
  clearApproveChanged: "CLEAR_SUCCESS"
};
