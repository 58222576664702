import {
	accessRightsConstants
}from "./constants"

const initialState = {
	changed          : null,
	error            : null,
	isSending        : false,
	paged            : null,
	additionsPaged   : null,
	exceptionsPaged  : null,
	categoryList     : null,
	territoryList    : null,
	controleTypeList : null,
	countItems       : null
}

export default (state = initialState, action) => {
	switch (action.type){
		case accessRightsConstants.createAddition.REQUEST:
		case accessRightsConstants.createException.REQUEST:
		case accessRightsConstants.createAccessRights.REQUEST:
		case accessRightsConstants.deleteAddition.REQUEST:
		case accessRightsConstants.deleteException.REQUEST:
		case accessRightsConstants.getOrgsAndRightsPaged.REQUEST:
		case accessRightsConstants.getAdditionsListPaged.REQUEST:
		case accessRightsConstants.getExceptionsListPaged.REQUEST:
		case accessRightsConstants.getReGosControlTypeList.REQUEST:
		case accessRightsConstants.getReGosCategoryList.REQUEST:
		case accessRightsConstants.getReGosTerritorialActivityList.REQUEST:
			return {
				...state,
				changed   : null,
				isSending : true
			}
		case accessRightsConstants.createAddition.FAILURE:
		case accessRightsConstants.createException.FAILURE:
		case accessRightsConstants.createAccessRights.FAILURE:
		case accessRightsConstants.deleteAddition.FAILURE:
		case accessRightsConstants.deleteException.FAILURE:
		case accessRightsConstants.getOrgsAndRightsPaged.FAILURE:
		case accessRightsConstants.getAdditionsListPaged.FAILURE:
		case accessRightsConstants.getExceptionsListPaged.FAILURE:
		case accessRightsConstants.getReGosControlTypeList.FAILURE:
		case accessRightsConstants.getReGosCategoryList.FAILURE:
		case accessRightsConstants.getReGosTerritorialActivityList.FAILURE:
			return {
				...state,
				error     : action.payload.result,
				isSending : false

			}

		case accessRightsConstants.createAddition.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Включение успешно создано!'
			}

		case accessRightsConstants.createException.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Исключение успешно создано!'
			}

		case accessRightsConstants.createAccessRights.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Права доступа успешно обновлены!'
			}

		case accessRightsConstants.deleteAddition.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Включение успешно удалено!'
			}

		case accessRightsConstants.deleteException.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : 'Исключение успешно удалено!'
			}

		case accessRightsConstants.getOrgsAndRightsPaged.SUCCESS:
			return {
				...state,
				isSending : false,
				paged     : action.payload.result
			}

		case accessRightsConstants.getAdditionsListPaged.SUCCESS:
			return {
				...state,
				additionsPaged : action.payload.result.items,
				countItems     : action.payload.result.count,
				isSending      : false
			}

		case accessRightsConstants.getExceptionsListPaged.SUCCESS:
			return {
				...state,
				countItems      : action.payload.result.count,
				exceptionsPaged : action.payload.result.items,
				isSending       : false
			}

		case accessRightsConstants.getReGosControlTypeList.SUCCESS:
			return {
				...state,
				isSending        : false,
				controleTypeList : action.payload.result
			}

		case accessRightsConstants.getReGosCategoryList.SUCCESS:
			return {
				...state,
				isSending    : false,
				categoryList : action.payload.result
			}

		case accessRightsConstants.getReGosTerritorialActivityList.SUCCESS:
			return {
				...state,
				isSending     : false,
				territoryList : action.payload.result
			}

		case accessRightsConstants.clearAssignments:
			return {
				...state,
				categoryList     : null,
				territoryList    : null,
				error            : null,
				controleTypeList : null,
				isSending        : false,
				paged            : null,
				exceptionsPaged  : null,
				additionsPaged   : null,
				changed          : null
			}
		default:
			return state
	}
}