export const fastReportConstants = {
	SettingFR: {
		REQUEST : 'SETTING_FR_REQUEST',
		SUCCESS : 'SETTING_FR_SUCCESS',
		FAILURE : 'SETTING_FR_FAILURE'
	},
	DeleteFR: {
		REQUEST : 'DELETE_FR_REQUEST',
		SUCCESS : 'DELETE_FR_SUCCESS',
		FAILURE : 'DELETE_FR_FAILURE'
	},
	GetList: {
		REQUEST : 'GET_LIST_FR_REQUEST',
		SUCCESS : 'GET_LIST_FR_SUCCESS',
		FAILURE : 'GET_LIST_FR_FAILURE'
	},
	GetById: {
		REQUEST : 'GET_BY_ID_FR_REQUEST',
		SUCCESS : 'GET_BY_ID_FR_SUCCESS',
		FAILURE : 'GET_BY_ID_FR_FAILURE'
	},
	GetChildReOrgsForDisplay: {
		REQUEST : 'GET_CHILD_REORGS_FOR_DISPLAY_REQUEST',
		SUCCESS : 'GET_CHILD_REORGS_FOR_DISPLAY_SUCCESS',
		FAILURE : 'GET_CHILD_REORGS_FOR_DISPLAY_FAILURE'
	},
	GetAllConstructureSettings: {
		REQUEST : 'GET_ALL_CONSTRUCTURE_SETTINGS_REQUEST',
		SUCCESS : 'GET_ALL_CONSTRUCTURE_SETTINGS_SUCCESS',
		FAILURE : 'GET_ALL_CONSTRUCTURE_SETTINGS_FAILURE'
	},
	GetAllRegionsDistricts: {
		REQUEST : 'GET_ALL_REGIONS_DISTRICTS_REQUEST',
		SUCCESS : 'GET_ALL_REGIONS_DISTRICTS_SUCCESS',
		FAILURE : 'GET_ALL_REGIONS_DISTRICTS_FAILURE'
	},
	GetReviewSettings: {
		REQUEST : 'GET_REVIEW_SETTINGS_REQUEST',
		SUCCESS : 'GET_REVIEW_SETTINGS_SUCCESS',
		FAILURE : 'GET_REVIEW_SETTINGS_FAILURE'
	},
	GetResolutionAuthors: {
		REQUEST : 'GET_RESOLUTION_AUTHORS_REQUEST',
		SUCCESS : 'GET_RESOLUTION_AUTHORS_SUCCESS',
		FAILURE : 'GET_RESOLUTION_AUTHORS_FAILURE'
	},
	CLEAR_RESULT: 'CLEAR_RESULT_FR_REQUEST'
}
