import {
	appealsCollectiveConstants
}from './constants'

const initialState = {
	CollectiveAppealsSending      : false,
	CollectiveAppeals             : null,
	applicantsSending             : false,
	applicants                    : null,
	applicantsOrgSending          : false,
	applicantsOrg                 : null,
	applicantsOrgList             : null,
	CollectiveAppealActionResult  : null,
	CollectiveAppealSending       : false,
	UserExistInAppealSending      : false,
	UserExistInAppeal             : null,
	JoinToCollectiveAppealSending : false,
	JoinToCollectiveAppealResult  : null,
	InviteResult                  : null,
	InviteSending                 : false,
	invitedUsers                  : null,
	invitedUsersSending           : false,
	isUserHaveInvite              : null,
	isUserHaveInviteSending       : null,
	pendingAppealData             : null,
	pendingAppealDataSending      : false,
	isFormed                      : false

}

export default function (state = initialState, action){
	switch (action.type){
		case appealsCollectiveConstants.GetCollectiveAppeals.REQUEST:{
			return {
				...state,
				CollectiveAppealsSending: true
			}
		}
		case appealsCollectiveConstants.GetCollectiveAppeals.SUCCESS:
			return {
				...state,
				CollectiveAppealsSending : false,
				CollectiveAppeals        : action.payload.result
			}
		case appealsCollectiveConstants.GetCollectiveAppeals.FAILURE:
			return {
				...state,
				CollectiveAppealsSending : false,
				error                    : action.payload
			}
		case appealsCollectiveConstants.GetCollectiveAppeals.CLEAR:
			return {
				...state,
				CollectiveAppeals: null
			}

		case appealsCollectiveConstants.GetAppealUsers.REQUEST:{
			return {
				...state,
				applicantsSending: true
			}
		}
		case appealsCollectiveConstants.GetAppealUsers.SUCCESS:
			return {
				...state,
				applicantsSending : false,
				applicants        : action.payload.result
			}
		case appealsCollectiveConstants.GetAppealUsers.FAILURE:
			return {
				...state,
				applicantsSending : false,
				error             : action.payload
			}
		case appealsCollectiveConstants.GetAppealUsers.CLEAR:
			return {
				...state,
				applicants: null
			}

		case appealsCollectiveConstants.GetOrgAppealUsers.REQUEST:{
			return {
				...state,
				applicantsOrg       : null, 
				applicantsOrgSending: true
			}
		}
		case appealsCollectiveConstants.GetOrgAppealUsers.SUCCESS:
			return {
				...state,
				applicantsOrgSending : false,
				applicantsOrg        : action.payload.result
			}
		case appealsCollectiveConstants.GetOrgAppealUsers.FAILURE:
			return {
				...state,
				applicantsOrgSending : false,
				error                : action.payload
			}
		case appealsCollectiveConstants.GetOrgAppealUsers.CLEAR:
			return {
				...state,
				applicantsOrg: null
			}

		case appealsCollectiveConstants.SubmitAppeal.REQUEST:{
			return {
				...state,
				CollectiveAppealSending: true
			}
		}
		case appealsCollectiveConstants.SubmitAppeal.SUCCESS:
			return {
				...state,
				CollectiveAppealSending      : false,
				CollectiveAppealActionResult : action.payload.result
			}
		case appealsCollectiveConstants.SubmitAppeal.FAILURE:
			return {
				...state,
				CollectiveAppealSending      : false,
				error                        : action.payload,
				CollectiveAppealActionResult : action.payload
			}
		case appealsCollectiveConstants.SubmitAppeal.CLEAR:
			return {
				...state,
				CollectiveAppealActionResult : null,
				CollectiveAppealSending      : false

			}

		case appealsCollectiveConstants.InviteUserToAppeal.REQUEST:{
			return {
				...state,
				InviteSending : true,
				InviteResult  : null
			}
		}
		case appealsCollectiveConstants.InviteUserToAppeal.SUCCESS:
			return {
				...state,
				InviteSending : false,
				InviteResult  : action.payload.result
			}
		case appealsCollectiveConstants.InviteUserToAppeal.FAILURE:
			return {
				...state,
				InviteSending : false,
				error         : action.payload,
				InviteResult  : action.payload
			}
		case appealsCollectiveConstants.InviteUserToAppeal.CLEAR:
			return {
				...state,
				InviteResult: null
			}

		case appealsCollectiveConstants.DeclineInvitation.REQUEST:{
			return {
				...state,
				CollectiveAppealSending: true
			}
		}
		case appealsCollectiveConstants.DeclineInvitation.SUCCESS:
			return {
				...state,
				CollectiveAppealSending      : false,
				CollectiveAppealActionResult : action.payload.result
			}
		case appealsCollectiveConstants.DeclineInvitation.FAILURE:
			return {
				...state,
				CollectiveAppealSending : false,
				error                   : action.payload
			}
		case appealsCollectiveConstants.DeclineInvitation.CLEAR:
			return {
				...state,
				CollectiveAppealActionResult: null
			}

		case appealsCollectiveConstants.JoinToCollectiveAppeal.REQUEST:{
			return {
				...state,
				JoinToCollectiveAppealSending : true,
				JoinToCollectiveAppealResult  : null
			}
		}
		case appealsCollectiveConstants.JoinToCollectiveAppeal.SUCCESS:
			return {
				...state,
				JoinToCollectiveAppealSending : false,
				JoinToCollectiveAppealResult  : action.payload.result
			}
		case appealsCollectiveConstants.JoinToCollectiveAppeal.FAILURE:
			return {
				...state,
				CollectiveAppealSending      : false,
				error                        : action.payload,
				JoinToCollectiveAppealResult : action.payload
			}
		case appealsCollectiveConstants.JoinToCollectiveAppeal.CLEAR:
			return {
				...state,
				JoinToCollectiveAppealResult: null
			}

		case appealsCollectiveConstants.CheckUserExistInAppeal.REQUEST:{
			return {
				...state,
				UserExistInAppealSending: true
			}
		}
		case appealsCollectiveConstants.CheckUserExistInAppeal.SUCCESS:
			return {
				...state,
				UserExistInAppealSending : false,
				UserExistInAppeal        : action.payload.result
			}
		case appealsCollectiveConstants.CheckUserExistInAppeal.FAILURE:
			return {
				...state,
				UserExistInAppealSending : false,
				error                    : action.payload
			}
		case appealsCollectiveConstants.CheckUserExistInAppeal.CLEAR:
			return {
				...state,
				UserExistInAppeal: null
			}

		case appealsCollectiveConstants.GetInvitedUsers.REQUEST:{
			return {
				...state,
				invitedUsersSending: true
			}
		}
		case appealsCollectiveConstants.GetInvitedUsers.SUCCESS:
			return {
				...state,
				invitedUsersSending : false,
				invitedUsers        : action.payload.result
			}
		case appealsCollectiveConstants.GetInvitedUsers.FAILURE:
			return {
				...state,
				invitedUsersSending : false,
				error               : action.payload
			}
		case appealsCollectiveConstants.GetInvitedUsers.CLEAR:
			return {
				...state,
				invitedUsers: null
			}

		case appealsCollectiveConstants.CheckUserHaveInvite.REQUEST:{
			return {
				...state,
				isUserHaveInviteSending : true,
				isUserHaveInvite        : null
			}
		}
		case appealsCollectiveConstants.CheckUserHaveInvite.SUCCESS:
			return {
				...state,
				isUserHaveInviteSending : false,
				isUserHaveInvite        : action.payload.result
			}
		case appealsCollectiveConstants.CheckUserHaveInvite.FAILURE:
			return {
				...state,
				isUserHaveInviteSending : false,
				error                   : action.payload,
				isUserHaveInvite        : action.payload
			}
		case appealsCollectiveConstants.CheckUserHaveInvite.CLEAR:
			return {
				...state,
				isUserHaveInvite: null
			}

		case appealsCollectiveConstants.GetPendnigAppealData.REQUEST:{
			return {
				...state,
				pendingAppealDataSending : true,
				pendingAppealData        : null
			}
		}
		case appealsCollectiveConstants.GetPendnigAppealData.SUCCESS:
			return {
				...state,
				pendingAppealDataSending : false,
				pendingAppealData        : action.payload.result,
				isFormed                 : true
			}
		case appealsCollectiveConstants.GetPendnigAppealData.FAILURE:
			return {
				...state,
				pendingAppealDataSending : false,
				error                    : action.payload,
				pendingAppealData        : action.payload
			}
		case appealsCollectiveConstants.GetPendnigAppealData.CLEAR:
			return {
				...state,
				pendingAppealData: null
			}

		case appealsCollectiveConstants.GetOrgCollectiveAppealUserList.REQUEST:{
			return {
				...state,
				applicantsOrgList       : null, 
				applicantsOrgSending	: true
			}
		}
		case appealsCollectiveConstants.GetOrgCollectiveAppealUserList.SUCCESS:
			return {
				...state,
				applicantsOrgSending 	 : false,
				applicantsOrgList        : action.payload.result
			}
		case appealsCollectiveConstants.GetOrgCollectiveAppealUserList.FAILURE:
			return {
				...state,
				applicantsOrgSending : false,
				error                : action.payload
			}
		case appealsCollectiveConstants.GetOrgCollectiveAppealUserList.CLEAR:
			return {
				...state,
				applicantsOrgList: null
			}

		default:
			return state
	}
}
