const i18n = {

  orgHeader: {
    1: "Календарь организации",
    0: "Каляндар арганізацыі",
  },
  month_1: {
    1: "Январь",
    0: "Студзень",
  },
  month_2: {
    1: "Февраль",
    0: "Люты",
  },
  month_3: {
    1: "Март",
    0: "Сакавік",
  },
  month_4: {
    1: "Апрель",
    0: "Красавік",
  },
  month_5: {
    1: "Май",
    0: "Май",
  },
  month_6: {
    1: "Июнь",
    0: "Чэрвень",
  },
  month_7: {
    1: "Июль",
    0: "Ліпень",
  },
  month_8: {
    1: "Август",
    0: "Жнівень",
  },
  month_9: {
    1: "Сентябрь",
    0: "Верасень",
  },
  month_10: {
    1: "Октябрь",
    0: "Кастрычнік",
  },
  month_11: {
    1: "Ноябрь",
    0: "Лістапад",
  },
  month_12: {
    1: "Декабрь",
    0: "Снежань",
  },
  monday: {
    1: "Пн",
    0: "Пн",
  },
  tuesday: {
    1: "Вт",
    0: "Ат",
  },
  wednesday: {
    1: "Ср",
    0: "Ас",
  },
  thursday: {
    1: "Чт",
    0: "Чц",
  },
  friday: {
    1: "Пт",
    0: "Пт",
  },
  saturday: {
    1: "Сб",
    0: "Сб",
  },
  sunday: {
    1: "Вс",
    0: "Нд",
  },
  submitButton: {
    1: "Сохранить изменения",
    0: "Захаваць змены",
  },
  workDays: {
    1: "Рабочие дни",
    0: "Працоўныя дні",
  },
  weekend: {
    1: "Выходные дни",
    0: "Выхадныя дні",
  },
  calendarSuccessUpdated: {
    1: "Данные календаря успешно обновлены!",
    0: "Дадзеныя календара паспяхова абноўлены!",
  },
  workScheduleSuccessUpdated: {
    1: "Данные времени работы успешно обновлены!",
    0: "Даныя часу працы паспяхова абноўлены!",
  },
  specialWorkScheduleSuccessUpdated: {
    1: "Данные особого времени работы успешно обновлены!",
    0: "Даныя асаблівага часу працы паспяхова абноўлены!",
  }
};

export default i18n;
