import {
	applicantNotificationsConstants
}from "./constants"
import {
	userConstants
}from "../../../store/constants/user.constants"
import notice from "../../../components/Notice"

const initialState = {
	//getCountNotRead;
	countNotRead         : 0,
	getCountNotReadError : null,

	//getFirstNotifications
	notifications              : [],
	loadingFirstNotifications  : false,
	getFirstNotificationsError : null,

	//getFirstNotifications
	getPagedNotificationsError : null,
	loadingPagedNotifications  : false,
	pagedNotifications         : null,

	//downloadNotificationFile
	isNotificationFileLoading : false,
	notificationFile          : null
}

export default function (state = initialState, action){
	switch (action.type){
		case userConstants.Login.SUCCESS :{
			return {
				...state,
				notifications: []
			}
		}

		//getCountNotRead
		case applicantNotificationsConstants.getCountNotRead.REQUEST :
			return {
				...state,
				getCountNotReadError: null
			}
		case applicantNotificationsConstants.getCountNotRead.SUCCESS :
			return {
				...state,
				countNotRead: action.payload.result
			}
		case applicantNotificationsConstants.getCountNotRead.FAILURE :
			return {
				...state,
				getCountNotReadError: action.payload.err.data.errors
			}
		case applicantNotificationsConstants.getFirstNotifications.REQUEST :
			return {
				...state,
				notifications              : [],
				loadingFirstNotifications  : true,
				getFirstNotificationsError : null
			}
		case applicantNotificationsConstants.getFirstNotifications.SUCCESS :
			return {
				...state,
				notifications             : action.payload.result,
				loadingFirstNotifications : false
			}
		case applicantNotificationsConstants.getFirstNotifications.FAILURE :
			return {
				...state,
				notifications             : [],
				loadingFirstNotifications : false,
				getCountNotReadError      : action.payload.err.data.errors
			}
		case applicantNotificationsConstants.getPagedNotifications.REQUEST :
			return {
				...state,
				loadingPagedNotifications  : true,
				getFirstNotificationsError : null
			}
		case applicantNotificationsConstants.getPagedNotifications.SUCCESS :
			return {
				...state,
				loadingPagedNotifications : false,
				pagedNotifications        : action.payload.result
			}
		case applicantNotificationsConstants.getPagedNotifications.FAILURE :
			return {
				...state,
				pagedNotifications         : null,
				loadingPagedNotifications  : false,
				getPagedNotificationsError : action.payload.err.data.errors
			}
		case applicantNotificationsConstants.downloadNotificationFile.REQUEST :
			return {
				...state,
				isNotificationFileLoading : true,
				notificationFile          : null
			}
		case applicantNotificationsConstants.downloadNotificationFile.SUCCESS :
			return {
				...state,
				isNotificationFileLoading : false,
				notificationFile          : action.payload.result
			}
		case applicantNotificationsConstants.downloadNotificationFile.FAILURE :
			return {
				...state,
				isNotificationFileLoading : false,
				notificationFile          : null
			}
		case applicantNotificationsConstants.getPagedNotifications.CLEAR :
			return {
				...state,
				notifications              : [],
				loadingFirstNotifications  : false,
				getFirstNotificationsError : null,
				getPagedNotificationsError : null,
				loadingPagedNotifications  : false,
				isNotificationFileLoading  : false,
				notificationFile           : null
			}
		default :
			return state
	}
}
